import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="header-logo">
      <img [src]="brand?.logo" alt="Brand Logo" (load)="onImageLoad()" (error)="onImageError($event)" [hidden]="logoError">
      <div *ngIf="logoError" class="brand-fallback">{{ brand?.name }}</div>
    </div>
    <div class="location-header">{{locationName}}</div>
    <div class="room-header">{{roomName}}</div>
  `,
  styles: [`
    .header-logo {
      margin-bottom: 20px;
    }
    .brand-fallback {
      font-size: 32px;
      color: #3e4c88;
      margin-bottom: 20px;
    }
    .location-header {
      font-size: 32px;
      color: #3e4c88;
      margin-bottom: 20px;
    }
    .room-header {
      font-size: 28px;
      margin-bottom: 10px;
    }
  `]
})
export class HeaderComponent {
  @Input() brand!: any;
  @Input() locationName!: string;
  @Input() roomName!: string;

  logoError: boolean = false;

  // Handle successful image load
  onImageLoad() {
    this.logoError = false;  // Ensure that logoError is reset on successful load
  }

  // Handle image load failure
  onImageError(event: Event) {
    this.logoError = true;  // Set logoError to true if the image fails to load
  }
}
