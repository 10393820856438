import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './modules/header/header.component';
import { StatusBarComponent } from './modules/status-bar/status-bar.component';
import { CardContainerComponent } from './modules/card-container/card-container.component';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { iotApiServiceService } from './services/iot-api/iot-api-service.service';
import { StatusBarService } from '../app/services/status-bar/status-bar-service.service'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    StatusBarComponent,
    CardContainerComponent,
  ],
  template: `
    <app-status-bar />
    <div
      class="content"
      [ngStyle]="{ 'background-color': selectedBrand?.colors?.background }"
    >
      <app-header
        [brand]="selectedBrand"
        [locationName]="selectedLocation!.name"
        [roomName]="selectedRoom!.name"
      ></app-header>
      <app-card-container
        [brand]="selectedBrand"
        [location]="selectedLocation"
        [room]="selectedRoom"
        [playables]="playables"
        [availablePlayables]="availablePlayables"
      ></app-card-container>
    </div>
  `,
  styles: [
    `
      .content {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        box-sizing: border-box;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  configuration: any = {};
  selectedBrand: any = {};
  selectedLocation: any = {};
  selectedRoom: any = {};
  playables: any[] = [];
  availablePlayables: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private iotService: iotApiServiceService,
    private renderer: Renderer2,
    private statusBarService: StatusBarService
  ) { }

  ngOnInit() {
    // Set a default error message for missing parameters
    this.statusBarService.showMessage("Missing query parameters: brand, location, and room are required!", 0, true);

    this.route.queryParams
      .pipe(
        filter(params => params['brand'] && params['location'] && params['room']),
      )
      .subscribe(params => {
        const brandName = params['brand'];
        const locationName = params['location'];
        const roomName = params['room'];

        this.statusBarService.hideMessage();

        // Fetch the configuration file for the current environment
        this.http.get(environment.configFile).subscribe((config: any) => {
          this.configuration = config;

          // Set the API root URL from the environment or the config file
          this.iotService.setApiRootUrl(environment.apiRootUrl);

          // Load the rest of the configuration based on query params
          this.loadConfiguration(brandName, locationName, roomName);
        });
      });
  }

  loadConfiguration(brandName: string, locationName: string, roomName: string) {
    const selectedBrand = this.configuration.brands.find(
      (b: any) => b.name === brandName
    );
    if (selectedBrand) {
      this.selectedBrand = selectedBrand;

      // Find the selected location and room
      this.selectedLocation = selectedBrand.locations.find(
        (l: any) => l.name === locationName
      );
      if (this.selectedLocation) {
        this.selectedRoom = this.selectedLocation.rooms.find(
          (r: any) => r.name === roomName
        );
        if (this.selectedRoom) {
          this.playables = this.configuration.playables;
          this.availablePlayables = this.configuration.playables.filter(
            (p: any) => this.selectedRoom.availablePlayables.includes(p.id)
          );
        }
        else {
          this.statusBarService.showMessage("Impossible to find room in current configuration! Can't proceed!");
        }
      }
      else {
        this.statusBarService.showMessage("Impossible to find location in current configuration! Can't proceed!");
      }

      this.setBodyBackgroundColor(this.selectedBrand.colors.background);
    }
    else {
      this.statusBarService.showMessage("Impossible to find brand in current configuration! Can't proceed!");
    }
  }

  setBodyBackgroundColor(color: string) {
    this.renderer.setStyle(document.body, 'background-color', color);
  }
}
