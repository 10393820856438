import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusBarService } from '../../services/status-bar/status-bar-service.service'

@Component({
  selector: 'app-status-bar',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div 
      class="status-bar" 
      [ngClass]="{ 'error': isError }" 
      *ngIf="isVisible">
      <span class="material-icons" style="font-size: 20px; margin-right: 10px;">
        {{ isError ? 'error' : 'check_circle' }}
      </span>
      {{ message }}
    </div>
  `,
  styles: [`
    .status-bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #4caf50;
      color: white;
      padding: 10px;
      border-radius: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      transition: background-color 0.3s ease-in-out;
    }
    
    .status-bar.error {
      background-color: #f44336;
    }
  `]
})
export class StatusBarComponent implements OnInit {
  message: string = '';
  isError: boolean = false;
  isVisible: boolean = false;

  constructor(private statusBarService: StatusBarService) { }

  ngOnInit() {
    // Subscribe to the service observables to get the status bar updates
    this.statusBarService.message$.subscribe((msg: string) => this.message = msg);
    this.statusBarService.isError$.subscribe((isErr: boolean) => this.isError = isErr);
    this.statusBarService.isVisible$.subscribe((visible: boolean) => this.isVisible = visible);
  }
}
