import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'app-card-container',
  standalone: true,
  imports: [CommonModule, CardComponent],
  template: `
    <div class="card-container">
      <app-card
        *ngFor="let card of pagedPlayables"
        [brand]="brand"
        [location]="location"
        [room]="room"
        [playable]="card"
        [isLocked]="!isPlayableAvailable(card.id)">
      </app-card>
    </div>

    <div class="paging">
      <button (click)="prevPage()"><span class="material-icons" *ngIf="(this.currentPage > 1)">arrow_back</span></button>
      <button (click)="nextPage()"><span class="material-icons" *ngIf="((this.currentPage * this.itemsPerPage) < this.playables.length)">arrow_forward</span></button>
    </div>
  `,
  styles: [`
    .card-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      width: 100%;
    }

    .paging {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .paging button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 28px;
      color: #4caf50;
    }
  `]
})
export class CardContainerComponent implements OnChanges {
  @Input() brand!: any;
  @Input() location!: any;
  @Input() room!: any;
  @Input() availablePlayables: any[] = [];
  @Input() playables: any[] = [];

  pagedPlayables: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 6;

  ngOnChanges() {
    this.updatePagedPlayables();
  }

  updatePagedPlayables() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.pagedPlayables = this.playables.slice(startIndex, endIndex);
  }

  nextPage() {
    if ((this.currentPage * this.itemsPerPage) < this.playables.length) {
      this.currentPage++;
      this.updatePagedPlayables();
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePagedPlayables();
    }
  }

  isPlayableAvailable(playableId: string) {
    return this.availablePlayables.some(playable => playable.id === playableId);
  }
}
