import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="header-logo">
      <img [src]="brand?.logo" alt="Brand Logo" (load)="onImageLoad()" (error)="onImageError($event)" [hidden]="logoError">
      <button class="" (click)="toggleFullScreen()">{{ isFullScreen ? 'Exit Fullscreen' : 'Enter Fullscreen' }}</button>
      <div *ngIf="logoError" class="brand-fallback">{{ brand?.name }}</div>
    </div>
    <div>
    <div class="location-header">{{locationName}}</div>
    <div class="room-header">{{roomName}}
    </div>
  `,
  styles: [`
    .header-logo {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .brand-fallback {
      font-size: 32px;
      color: #3e4c88;
      margin-bottom: 20px;
    }
    .location-header {
      font-size: 32px;
      color: #3e4c88;
      margin-bottom: 20px;
    }
    .room-header {
      font-size: 28px;
      margin-bottom: 10px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #333;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 25px;
      cursor: pointer;
      font-size: 16px;
      margin-top: 20px;
      text-align: left;
    }
  `]
})
export class HeaderComponent {
  @Input() brand!: any;
  @Input() locationName!: string;
  @Input() roomName!: string;

  logoError: boolean = false;
  isFullScreen = false;

  // Define the wakeLock property
  private wakeLock: any = null;

  async toggleFullScreen() {
    if (!this.isFullScreen) {
      // Enter full-screen mode
      const elem = document.documentElement; // Fullscreen the entire page
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if ((elem as any).webkitRequestFullscreen) { /* Safari */
        (elem as any).webkitRequestFullscreen();
      } else if ((elem as any).msRequestFullscreen) { /* IE11 */
        (elem as any).msRequestFullscreen();
      }
      this.isFullScreen = true;
      await this.requestWakeLock();
    } else {
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) { /* Safari */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) { /* IE11 */
        (document as any).msExitFullscreen();
      }
      this.isFullScreen = false;
      this.releaseWakeLock();
    }
  }

  async requestWakeLock() {
    try {
      if ('wakeLock' in navigator) {
        this.wakeLock = await (navigator as any).wakeLock.request('screen');
        console.log('Wake Lock is activated');
      } else {
        console.log('API Wake Lock not available');
      }
    } catch (err) {
      console.error('Error activating Wake Lock:', err);
    }
  }

  releaseWakeLock() {
    if (this.wakeLock) {
      this.wakeLock.release()
        .then(() => {
          console.log('Wake Lock deactivated');
        });
      this.wakeLock = null;
    }
  }

  // Handle successful image load
  onImageLoad() {
    this.logoError = false;  // Ensure that logoError is reset on successful load
  }

  // Handle image load failure
  onImageError(event: Event) {
    this.logoError = true;  // Set logoError to true if the image fails to load
  }
}
