import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StatusBarService {
    // BehaviorSubject to hold and emit the status bar message, error state, and visibility
    private messageSubject = new BehaviorSubject<string>('');
    private errorStateSubject = new BehaviorSubject<boolean>(false);
    private visibilitySubject = new BehaviorSubject<boolean>(false);

    // Observables to expose the status bar state
    message$ = this.messageSubject.asObservable();
    isError$ = this.errorStateSubject.asObservable();
    isVisible$ = this.visibilitySubject.asObservable();

    // Method to update the status bar
    showMessage(message: string, duration: number = 3000, isError: boolean = false,) {
        this.messageSubject.next(message);
        this.errorStateSubject.next(isError);
        this.visibilitySubject.next(true);

        if (duration > 0) {
            setTimeout(() => this.visibilitySubject.next(false), duration);
        }
    }

    // Method to hide the status bar manually
    hideMessage() {
        this.visibilitySubject.next(false);
    }
}
