import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { iotApiServiceService } from '../../services/iot-api/iot-api-service.service';
import { StatusBarService } from '../../services/status-bar/status-bar-service.service'

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="card" [ngClass]="{'locked': isLocked, 'active': isActive}" [ngStyle]="{'background-color': getCardBackgroundColor()}">
      <div class="card-icon">
        <img [src]="playable!.logo" alt="logo" width="48" height="48">
      </div>
      <div class="card-title">{{playable!.name}}</div>
      <div class="card-description">{{playable!.description}}</div>
      <button 
        [disabled]="isLocked || isLoading" 
        [ngStyle]="{'background-color': getButtonColor()}" 
        (click)="handleClick()">
        <span class="material-icons">{{ isLocked ? 'lock' : (isActive ? 'stop' : 'play_arrow') }}</span> 
        {{ isLocked ? 'Locked' : (isActive ? 'Stop' : 'Play') }}
      </button>
    </div>
  `,
  styles: [`
    .card {
      border-radius: 15px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 200px;
      height: 75%
    }

    .card-title {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 16px;
    }

    .card.locked {
      color: #999;
    }

    .card.active {
      border: 2px solid #000;
    }

    button {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #333;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 25px;
      cursor: pointer;
      font-size: 16px;
      margin-top: 20px;
      text-align: left;
    }

    button.stop {
      background-color: #3e4c88;
    }

    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }

    .card-icon {
      font-size: 24px;
      color: #333;
      margin-bottom: 10px;
    }

    .material-icons {
      margin-right: 5px;
      font-size: 20px;
    }
  `]
})
export class CardComponent {
  @Input() brand!: any;
  @Input() location!: any;
  @Input() room!: any;
  @Input() playable!: any;
  @Input() isLocked = false;
  isActive: boolean = false;
  isLoading: boolean = false;

  constructor(
    private iotApiService: iotApiServiceService,
    private statusBarService: StatusBarService
  ) { }

  // Handle Play/Stop button click
  handleClick() {
    if (this.isActive) {
      this.stopDevice();
    } else {
      this.startDevice();
    }
  }

  // Method to start the device
  startDevice() {
    this.isLoading = true;
    const observer = {
      next: (response: any) => {
        console.log('Device started:', response);
        this.isActive = true;
        this.isLoading = false;

        this.statusBarService.showMessage('Device started successfully!', 3000, false);
      },
      error: (error: any) => {
        console.error('Error starting device:', error);
        this.isLoading = false;

        this.statusBarService.showMessage('Failed to start device! Please try again.', 3000, true);
      }
    };

    if (this.room && this.playable) {
      this.room.devices!.forEach((device: any) => {
        this.playable.signals!.forEach((signal: any) => {
          this.iotApiService.startDevice(this.brand!.apiKey, device.deviceName, signal.signalId, signal.coils).subscribe(observer);
        });
      });
    }
  }

  // Method to stop the device
  stopDevice() {
    this.isLoading = true;
    const observer = {
      next: (response: any) => {
        console.log('Device stopped:', response);
        this.isActive = false;
        this.isLoading = false;

        this.statusBarService.showMessage('Device stopped successfully!', 3000, false);
      },
      error: (error: any) => {
        console.error('Error stopping device:', error);
        this.isLoading = false;

        this.statusBarService.showMessage('Failed to stop device! Please try again.', 3000, true);
      }
    };

    if (this.room && this.playable) {
      this.room.devices!.forEach((device: any) => {
        this.iotApiService.stopDevice(this.brand!.apiKey, device.deviceName, device.stopCoilsSignal).subscribe(observer);
      });
    }
  }

  getCardBackgroundColor() {
    return this.isLocked ? this.brand!.colors.nonAvailableCard : this.brand!.colors.availableCard;
  }

  getButtonColor() {
    if (this.isLocked) {
      return this.brand!.colors.lockedButton;
    }
    return this.isActive ? this.brand!.colors.stopButton : this.brand!.colors.playButton;
  }
}
