import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class iotApiServiceService {

  private apiRootUrl: string = '';

  constructor(private http: HttpClient) { }

  setApiRootUrl(url: string) {
    this.apiRootUrl = url;
  }

  // Method to start the device (Play)
  startDevice(apiKey: string,
    deviceId: string,
    fileId: string,
    coils: string): Observable<any> {
    const url = `${this.apiRootUrl}/v1/iot/start_playback/${deviceId}/${fileId}/${coils}`;
    const headers = new HttpHeaders({
      'Hapbee-APIKey': apiKey
    });
    return this.http.post(url, null, { headers });
  }

  // Method to stop the device (Stop)
  stopDevice(apiKey: string,
    deviceId: string,
    coils: string): Observable<any> {
    const url = `${this.apiRootUrl}/v1/iot/stop_playback/${deviceId}/${coils}`;
    const headers = new HttpHeaders({
      'Hapbee-APIKey': apiKey
    });
    return this.http.post(url, null, { headers });
  }
}
